
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$na-web-tracer-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
    //#ff5e38,
    tertiary: mat.$blue-palette,
  ),
  density: (
    scale: 0,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($na-web-tracer-theme);
  --warmOrange:rgb(255,94,56);
  --highlightOrange:rgb(255, 229, 222);
  --highlightBlue:rgba(8, 24, 53, 0.038);
  --narraveroBlue:#081835;
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($na-web-tracer-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($na-web-tracer-theme);
/* You can add global styles to this file, and also import other style files */

@import "bootstrap";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic{
  stroke: var(--warmOrange)!important;
}

.main-content-wrapper{
  flex: 1;
  height: 98%;;
}

app-dashboard, app-work-task-list, app-finished-task-list, app-tridy-list{
  height: 100%;
  width: 100%;
}

.task-toolbar .mdc-text-field.mdc-text-field--filled {
  background-color: transparent!important;
}

app-work-task-form, app-work-task-list, app-finished-task-list, app-finished-task-detail, app-tridy-dialog, app-tridy-list, app-tridy-detail {
  ::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    //background-color: var(--warmOrange);
    background-color: #c3c3c3;
    border-radius: 8px;
  }
}

.mat-step-icon-selected{
  background-color: var(--warmOrange)!important;
  //linear-gradient(90deg, rgb(8, 24, 53) 0%, rgb(255, 94, 56) 100%)!important;
}

app-work-task-form .mat-expansion-panel{
  box-shadow: none!important;
  background: none!important;
}

.error-snackbar{
  .mat-mdc-snack-bar-label{
    color: rgb(255, 184, 184)!important;
  }
/*   .mat-mdc-snackbar-surface{
    background: #ffb6c1!important;
  } */
}